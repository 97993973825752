import React from 'react'
import { Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import CafeThijssenLogo from '../images/brands/cafethijssen-logo.png';
import ActionPactLogo from '../images/brands/logo-actionpact.png';
import FysioManLogo from '../images/brands/logo-fysioman.png';
import SkicentrumMoserLogo from '../images/brands/logo-moser.png';
import PraktijkBalkeLogo from '../images/brands/logo-praktijkbalke.png';
import PurplebeanLogo from '../images/brands/purplebean-logo.png';
import UsabillaLogo from '../images/brands/usabilla-logo.svg';
import VodafoneZiggoLogo from '../images/brands/vfzg-logo.svg';
import VodafoneLogo from'../images/brands/vodafone-logo.png';



const Brands = () => (
  <div>
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
    >

      <Grid item xs md={4}>
        <Link to="/projects/usabilla/">
        <img src={ UsabillaLogo } className="skill-logo project-logo"  alt="Skills" />
        </Link>
      </Grid>
      <Grid item xs md={4}>
        <Link to="/projects/vodafone/">
        <img src={ VodafoneLogo } className="skill-logo project-logo"  alt="Skills" />
        </Link>
      </Grid>
      <Grid item xs md={4}>
        <Link to="/projects/actionpact/">
        <img src={ ActionPactLogo } className="skill-logo project-logo"  alt="Skills" />
        </Link>
      </Grid>
      <Grid item xs md={4}>
        <Link to="/projects/cafethijssen/">
        <img src={ CafeThijssenLogo } className="skill-logo project-logo" alt="Skills" />
        </Link>
      </Grid>
      <Grid item xs  md={4}>
        <Link to="/projects/vodafoneziggo/">
          <img src={ VodafoneZiggoLogo } className="skill-logo vfzg"  alt="Skills" /></Link>
      </Grid>
      <Grid item xs md={4}>
        <Link to="/projects/defysioman/">
        <img src={ FysioManLogo } className="skill-logo project-logo" alt="Skills" />
        </Link>
      </Grid>
    <Grid item xs md={4}>
      <Link to="/projects/skicentrummoser/">
      <img src={ SkicentrumMoserLogo } className="skill-logo project-logo"  alt="Skills" />
      </Link>
    </Grid>
      <Grid item xs  md={4}>
        <Link to="/projects/praktijkbalke/">
        <img src={ PraktijkBalkeLogo } className="skill-logo project-logo"  alt="Skills" />
        </Link>
    </Grid>
    <Grid item xs md={4}>
      <Link to="/projects/purplebean/">
    <img src={ PurplebeanLogo } className="skill-logo project-logo"  alt="Skills" />
      </Link>
    </Grid>
    </Grid>
  </div>
)


export default Brands;
