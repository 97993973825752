import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Brands from '../components/Brands'
import Grid from '@material-ui/core/Grid';
import ReturnArrow from '../images/return-arrow.svg';
import { Helmet } from 'react-helmet'


const Projects = () => (
    <Layout>
      <Helmet>
        <title>David Hildering [ my Projects ] - Freelance Front-end Developer</title>
        <meta name="description" content="Hi, My name is David Hildering. I am a freelance front-end developer from Utrecht."/>
      </Helmet>
      <Grid container
            className="link-list"
      >
        <Grid item xs={4}>
          <div>
            <Link to="/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Link to="/about/">About</Link>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Link to="/contact/">Contact</Link>
          </div>
        </Grid>
      </Grid>
        <Grid container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={8}>
          <div className='app-intro'>
            <Grid item xs={12}>
              <h2>My Projects</h2>
            </Grid>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  className="about-content">
              <Grid item md={6}>
                <p>In the last 5 years I did several projects, ranging from websites for small businesses to big e-commerce platforms and web-applications.</p>
                <p>Most of my work I did for VodafoneZiggo and Usabilla.
                  If you would like to know more about my work or have an opportunity to discuss,
                  please send me an <a className="link" href='mailto:mail@davidhildering.nl'>email</a> and I get back to you asap. </p>
              </Grid>
              <Grid item md={6}>
                <Brands />
              </Grid>
            </Grid>
          </div>
        </Grid>
    </Layout>
)

export default Projects;
